import { ref } from '@vue/composition-api'

export default function useFilterReport ({ baseUrl, fetchLists }) {
  const baseReportUrl = ref(baseUrl)
  const periodReportText = ref()
  const officeReportText = ref()

  const getReport = async ({ generatedUrl, periodText, officeText }) => {
    await fetchLists(generatedUrl)
    periodReportText.value = periodText
    officeReportText.value = officeText
  }

  return {
    baseReportUrl,
    periodReportText,
    officeReportText,
    getReport
  }
}
